export const paymentStatusesGroupCodes = {
    nop: "Ninguna orden pagada",
    aop: "Todas las ordenes pagadas",
    sop: "Algunas ordenes pagadas"
};

export const paymentStatusGroupMessageClass = {
    nop: "text-warning",
    aop: "text-success",
    sop: "text-info"
};
