export const deliveryStatusesGroupCodes = {
    nod: "Ninguna orden entregada",
    aod: "Todas las ordenes entregadas",
    sod: "Algunas ordenes entregadas"
};

export const deliveryStatusesGroupMessageClass = {
    nod: "text-warning",
    aod: "text-success",
    sod: "text-info"
};
